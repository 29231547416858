<template>
  <div class="layout-horizontal">
    trade
  </div>
</template>

<script>
export default {
  name: "TradePanel"
}
</script>

<style scoped>

</style>